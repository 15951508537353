import React from 'react';

export default function CompanySectionMaSeContentSections1() {
    return (
        <React.Fragment>
            <>
                <div className="relative isolate overflow-hidden bg-white py-24 sm:py-32">
  <div className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56" aria-hidden="true" />
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl lg:mx-0">
      <p className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">About</p>
      <p className="mt-6 text-xl leading-8 text-gray-700">We craft AI-powered omnichannel solutions for data-driven advertisers.</p>
    </div>
    <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
      <div className="relative lg:order-last lg:col-span-5">
        <svg className="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]" aria-hidden="true"><defs><pattern id="e87443c8-56e4-4c20-9111-55b82fa704e3" width={200} height={200} patternUnits="userSpaceOnUse"><path d="M0.5 0V200M200 0.5L0 0.499983" /></pattern></defs><rect width="100%" height="100%" strokeWidth={0} fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)" /></svg><figure className="border-l border-indigo-600 pl-8"><blockquote className="text-xl font-semibold leading-8 tracking-tight text-gray-900">
            <p>“Scale your paid ads with localized feeds, and drive accelerated results in record time – all while eliminating the pain points of manual ad management. With adBooster, the possibilities for growth and innovation are limitless.”</p>
          </blockquote>
          <figcaption className="mt-8 flex space-x-4"><img className="mt-1 h-10 w-10 flex-none rounded-full bg-gray-50" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/Tristan-Lowe.png" alt="" /><div className="text-sm leading-6">
              <div className="font-semibold text-gray-900">Tristan Lowe, CEO</div>
              <div className="text-gray-600">@tristan</div>
            </div>
          </figcaption></figure></div>
      <div className="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7">
        <p>adBooster revolutionizes the advertising landscape by bringing advanced concepts and technologies previously exclusive to e-commerce to industries like real estate, hospitality, automotive, and classifieds. Our platform applies a data-driven approach enhanced with smart feedback loops, ensuring high-quality output while reducing the cost per conversion. With adBooster, your inventory seamlessly matches with the right prospects across major ad networks at the right moment – leveraging the crucial elements of content, placement, and timing.</p>
        <ul className="mt-8 max-w-xl space-y-8 text-gray-600" role="list"><li className="flex space-x-3">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600"><path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" /></svg><span>
              <strong className="font-semibold text-gray-900">In sync. </strong>
              <span>Seamlessly in sync with your CRM or datasource.</span>
            </span>
          </li>
          <li className="flex space-x-3">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600"><path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" /></svg><span>
              <strong className="font-semibold text-gray-900">Custom feeds. </strong>
              <span>Create custom feeds from your inventory.</span>
            </span>
          </li>
          <li className="flex space-x-3">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600"><path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" /></svg><span>
              <strong className="font-semibold text-gray-900">Dynamic creatives. </strong>
              <span>Tailor dynamic creatives based on data-driven touchpoints. </span>
            </span>
          </li>
          <li className="flex space-x-3">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600"><path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" /></svg><span>
              <strong className="font-semibold text-gray-900">Single-source-of-truth. </strong>
              <span>Understand your audiences from a single-source-of-truth. </span>
            </span>
          </li>
          <li className="flex space-x-3">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600"><path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" /></svg><span>
              <strong className="font-semibold text-gray-900">Deep insights. </strong>
              <span>Leverage actionable insights.</span>
            </span>
          </li>
        </ul></div>
    </div>
  </div>
</div>


            </>
        </React.Fragment>
    );
}

