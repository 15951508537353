import React from 'react';

export default function IndexSectionMaSeFeatureSections3() {
    return (
        <React.Fragment>
            <>
                <div id="products" className="bg-white py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl sm:text-center">
      <h2 className="font-semibold leading-7 text-indigo-600 text-base" />
      <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">No omnichannel? We got it.</p>
      <p className="mt-6 text-lg leading-8 text-gray-600">Easily convert your inventory into high performing ads on social, programmatic, search and billboards</p>
    </div>
  </div>
  <div className="relative overflow-hidden pt-16">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <img className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/MOCKUP-off-plan-variant-A-6.jpg" alt="App screenshot" width={2432} height={1442} /><div className="relative" aria-hidden="true">
        <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
      </div>
    </div>
  </div>
  <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
    <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16"><div className="relative pl-9">
        <dt className="inline font-semibold text-gray-900">
          <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clipRule="evenodd" /></svg><span>Connect your CRM.</span>
        </dt>
        <dd className="inline"> Without a single line of code, we support them all.</dd>
      </div>
      <div className="relative pl-9">
        <dt className="inline font-semibold text-gray-900">
          <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clipRule="evenodd" /></svg><span>Unlock localization.</span>
        </dt>
        <dd className="inline"> Immerse your audience, without the tiring admin.</dd>
      </div>
      <div className="relative pl-9">
        <dt className="inline font-semibold text-gray-900">
          <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z" clipRule="evenodd" /></svg><span>Create feeds.</span>
        </dt>
        <dd className="inline"> Unlock the power of scale, match your inventory with the right prospects.</dd>
      </div>
      <div className="relative pl-9">
        <dt className="inline font-semibold text-gray-900">
          <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M10 2.5c-1.31 0-2.526.386-3.546 1.051a.75.75 0 01-.82-1.256A8 8 0 0118 9a22.47 22.47 0 01-1.228 7.351.75.75 0 11-1.417-.49A20.97 20.97 0 0016.5 9 6.5 6.5 0 0010 2.5zM4.333 4.416a.75.75 0 01.218 1.038A6.466 6.466 0 003.5 9a7.966 7.966 0 01-1.293 4.362.75.75 0 01-1.257-.819A6.466 6.466 0 002 9c0-1.61.476-3.11 1.295-4.365a.75.75 0 011.038-.219zM10 6.12a3 3 0 00-3.001 3.041 11.455 11.455 0 01-2.697 7.24.75.75 0 01-1.148-.965A9.957 9.957 0 005.5 9c0-.028.002-.055.004-.082a4.5 4.5 0 018.996.084V9.15l-.005.297a.75.75 0 11-1.5-.034c.003-.11.004-.219.005-.328a3 3 0 00-3-2.965zm0 2.13a.75.75 0 01.75.75c0 3.51-1.187 6.745-3.181 9.323a.75.75 0 11-1.186-.918A13.687 13.687 0 009.25 9a.75.75 0 01.75-.75zm3.529 3.698a.75.75 0 01.584.885 18.883 18.883 0 01-2.257 5.84.75.75 0 11-1.29-.764 17.386 17.386 0 002.078-5.377.75.75 0 01.885-.584z" clipRule="evenodd" /></svg><span>Be on brand.</span>
        </dt>
        <dd className="inline"> Personalize your content, translate your favorite creatives into dynamic templates.</dd>
      </div>
      <div className="relative pl-9">
        <dt className="inline font-semibold text-gray-900">
          <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M7.84 1.804A1 1 0 018.82 1h2.36a1 1 0 01.98.804l.331 1.652a6.993 6.993 0 011.929 1.115l1.598-.54a1 1 0 011.186.447l1.18 2.044a1 1 0 01-.205 1.251l-1.267 1.113a7.047 7.047 0 010 2.228l1.267 1.113a1 1 0 01.206 1.25l-1.18 2.045a1 1 0 01-1.187.447l-1.598-.54a6.993 6.993 0 01-1.929 1.115l-.33 1.652a1 1 0 01-.98.804H8.82a1 1 0 01-.98-.804l-.331-1.652a6.993 6.993 0 01-1.929-1.115l-1.598.54a1 1 0 01-1.186-.447l-1.18-2.044a1 1 0 01.205-1.251l1.267-1.114a7.05 7.05 0 010-2.227L1.821 7.773a1 1 0 01-.206-1.25l1.18-2.045a1 1 0 011.187-.447l1.598.54A6.993 6.993 0 017.51 3.456l.33-1.652zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" /></svg><span>Co-pilot campaigns.</span>
        </dt>
        <dd className="inline"> Stay on top of your performance, experiment with new markets and audiences.</dd>
      </div>
      <div className="relative pl-9">
        <dt className="inline font-semibold text-gray-900">
          <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path d="M4.632 3.533A2 2 0 016.577 2h6.846a2 2 0 011.945 1.533l1.976 8.234A3.489 3.489 0 0016 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234z" /><path fillRule="evenodd" d="M4 13a2 2 0 100 4h12a2 2 0 100-4H4zm11.24 2a.75.75 0 01.75-.75H16a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V15zm-2.25-.75a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75h-.01z" clipRule="evenodd" /></svg><span>Own your big data.</span>
        </dt>
        <dd className="inline"> Stay in control of your assets, get data-driven insights.</dd>
      </div>
      <div className="relative pl-9">
        <dt className="inline font-semibold text-gray-900">
          <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 22 22" fill="currentColor" aria-hidden="true"><path d="M19 4H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H5V6h14v12zM7.5 13h2v2H11V9H9.5v2.5h-2V9H6v6h1.5zm6.5 2h.75v1.5h1.5V15H17c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm.5-4.5h2v3h-2v-3z" /></svg><span>Feed pixels quality.</span>
        </dt>
        <dd className="inline"> Don’t clutter your pixels with low quality events, use smart feedback loops to credit conversions that matter up to 90 days post-event.</dd>
      </div>
      <div className="relative pl-9">
        <dt className="inline font-semibold text-gray-900">
          <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 22 22" fill="currentColor" aria-hidden="true"><path d="M12 7C6.48 7 2 9.24 2 12c0 2.24 2.94 4.13 7 4.77V20l4-4-4-4v2.73c-3.15-.56-5-1.9-5-2.73 0-1.06 3.04-3 8-3s8 1.94 8 3c0 .73-1.46 1.89-4 2.53v2.05c3.53-.77 6-2.53 6-4.58 0-2.76-4.48-5-10-5z" /></svg><span>360 customer journeys.</span>
        </dt>
        <dd className="inline"> Discover client journeys as they progress through your omnichannel with our proprietary single-source-of-truth model.</dd>
      </div>
      <div className="relative pl-9">
        <dt className="inline font-semibold text-gray-900">
          <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 22 22" fill="currentColor" aria-hidden="true"><path d="M21 8c-1.45 0-2.26 1.44-1.93 2.51l-3.55 3.56c-.3-.09-.74-.09-1.04 0l-2.55-2.55C12.27 10.45 11.46 9 10 9c-1.45 0-2.27 1.44-1.93 2.52l-4.56 4.55C2.44 15.74 1 16.55 1 18c0 1.1.9 2 2 2 1.45 0 2.26-1.44 1.93-2.51l4.55-4.56c.3.09.74.09 1.04 0l2.55 2.55C12.73 16.55 13.54 18 15 18c1.45 0 2.27-1.44 1.93-2.52l3.56-3.55c1.07.33 2.51-.48 2.51-1.93 0-1.1-.9-2-2-2z" /><path d="m15 9 .94-2.07L18 6l-2.06-.93L15 3l-.92 2.07L12 6l2.08.93zM3.5 11 4 9l2-.5L4 8l-.5-2L3 8l-2 .5L3 9z" /></svg><span>Actionable insights.</span>
        </dt>
        <dd className="inline"> Gain valuable insights about your inventory, add more top-performers and stop spending on under-performers.</dd>
      </div>
    </dl></div>
</div>


            </>
        </React.Fragment>
    );
}

