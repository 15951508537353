import React from 'react';

export default function CompanySectionMaSeLogoClouds10() {
    return (
        <React.Fragment>
            <>
                <div className="bg-gray-900 py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
      <div className="mx-auto w-full max-w-xl lg:mx-0">
        <h2 className="text-3xl font-bold tracking-tight text-white">Partners</h2>
        <p className="mt-6 text-lg leading-8 text-gray-300">Trusted by the most innovative teams.</p>
        <div className="mt-8 flex items-center gap-x-6">
          <a className="text-sm font-semibold text-white" target={"_blank"} href="mailto:welcome@adbooster.ae">
            <span>Contact us</span>
            <span aria-hidden="true">→</span>
          </a>
        </div>
      </div>
      <div className="mx-auto grid w-full max-w-xl grid-cols-2 items-center sm:gap-y-14 lg:mx-0 lg:max-w-none lg:pl-8 gap-y-12">
        <img className="object-contain object-left max-h-12 w-32" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/Autopilot-Logotype-RGB-White-Noicon-2.png" alt="Tuple" width={105} height={48} />
        <img className="max-h-12 object-contain object-left w-36" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/saleschannel-Logotype-RGB-White.png" alt="Reform" width={104} height={48} />
        <img className="max-h-12 object-contain object-left w-32" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/Adtropi-Logotype-RGB-White.png" alt="SavvyCal" width={140} height={48} />
        <img className="max-h-12 object-contain object-left w-36" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/Domain-Logotype-White.png" alt="Laravel" width={136} height={48} />
        <img className="w-full object-contain object-left max-h-12" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/STM-Logotype-RGB-Inv.png" alt="Transistor" width={158} height={48} />
        <img className="max-h-12 object-contain object-left w-36" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/HOP-Light-Logo-RGB.png" alt="Statamic" width={147} height={48} />
      </div>
    </div>
  </div>
</div>


            </>
        </React.Fragment>
    );
}

