import React from 'react';

export default function CompanySectionMaSeContactSections8() {
    return (
        <React.Fragment>
            <>
                <div className="bg-white py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
        <div>
          <p className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">Press</p>
          <p className="mt-4 leading-7 text-gray-600">For press inquiries</p>
        </div>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
          <div className="rounded-2xl p-10">
            <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600"><div><dt className="sr-only">Email</dt><dd><a className="font-semibold text-indigo-600" href="mailto:welcome@adbooster.ae">press@adbooster.ai</a></dd></div>
            </dl></div>
        </div>
      </div>
    </div>
  </div>
</div>


            </>
        </React.Fragment>
    );
}

