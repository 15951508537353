import React from 'react';

export default function IndexSectionMaSeFeatureSections10() {
    return (
        <React.Fragment>
            <>
                <div id="proptree" className="overflow-hidden bg-white py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
      <div className="lg:ml-auto lg:pl-4 lg:pt-4">
        <div className="lg:max-w-lg">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">proptr.ee (real estate only)</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">One synced link in bio.</p>
          <p className="mt-6 text-lg leading-8 text-gray-600">Personal shortlinks to generate leads from your team's social profiles – always ON, always in sync with your CRM.</p>
          <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none"><div className="relative pl-9">
              <dt className="inline font-semibold text-gray-900">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-indigo-600"><path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" /></svg><span>In sync.</span>
              </dt>
              <dd className="inline"> Keep your sales teams social profiles in-sync with your inventory, never miss out on personal referrals.</dd>
            </div>
            <div className="relative pl-9">
              <dt className="inline font-semibold text-gray-900">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-indigo-600"><path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" /></svg><span>Share to all spaces.</span>
              </dt>
              <dd className="inline"> Distribute your dynamic shortlinks among your organization and start driving high intent traffic.</dd>
            </div>
            <div className="relative pl-9">
              <dt className="inline font-semibold text-gray-900">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-indigo-600"><path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" /></svg><span>Your virtual ad network.</span>
              </dt>
              <dd className="inline"> Leverage your own virtual ad network and push centralized promotions, get insights.</dd>
            </div>
          </dl></div>
      </div>
      <div className="flex items-start justify-end lg:order-first">
        <img className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/MOCKUP-proptree-variant-A-4.jpg" alt="Product screenshot" width={2432} height={1442} /></div>
    </div>
  </div>
</div>


            </>
        </React.Fragment>
    );
}

