import React from 'react';

export default function IndexSectionMaSeFeatureSections18() {
    return (
        <React.Fragment>
            <>
                <div className="overflow-hidden bg-white py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto grid max-w-2xl grid-cols-1 space-x-8 space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
      <div className="lg:pr-4 lg:pt-4">
        <div className="lg:max-w-lg">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Actionable insights</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Accelerate results</p>
          <p className="mt-6 leading-8 text-gray-600 text-lg">Understand how your inventory resonates with your audience. Filter on metrics relevant to you. Understand what content drives your funnel, easily pause low performing content.</p>
          <div className="mt-8"><a className="inline-flex rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" href="#plans">Select plan</a></div>
          <figure className="mt-16 border-l border-gray-200 pl-8 text-gray-600"><blockquote className="text-sm leading-7">
              <p className="">“adBooster provided us the tools to scale our paid ads while removing the pain points and admin, I can now generate localized feeds with hundreds of on-brand creatives, all within seconds – and the best part, it's all in sync with our CRM. It's a true joy to experiment with new markets and drive results in timescales unimaginable before”</p>
            </blockquote>
            <figcaption className="mt-6 flex space-x-4 text-sm leading-6"><img className="h-6 w-6 flex-none rounded-full" src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80" alt="" /><div>
                <span className="font-semibold text-gray-900">Clair Hill</span>
                <span> – Marketing Manager</span>
              </div>
            </figcaption></figure></div>
      </div>
      <img className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:ml-0" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/MOCKUP-report-variant-A-3.png" alt="Product screenshot" width={2432} height={1442} /></div>
  </div>
</div>


            </>
        </React.Fragment>
    );
}

