import React from 'react';

export default function CompanySectionApHeCardHeadings13() {
    return (
        <React.Fragment>
            <>
                <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6" />


            </>
        </React.Fragment>
    );
}

