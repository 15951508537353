import React from 'react';

export default function IndexSectionMaSeLogoClouds2() {
    return (
        <React.Fragment>
            <>
                <div className="bg-white py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl lg:max-w-none">
      <h2 className="font-semibold leading-8 text-sm text-gray-900">Empowered by the world’s smartest tech</h2>
      <div className="mx-auto mt-10 grid grid-cols-4 items-start gap-x-8 gap-y-10 sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:grid-cols-5">
        <img className="col-span-2 object-contain object-left lg:col-span-1 w-40" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/google-cloud-black.png" alt="Transistor" width={158} height={48} /><img className="object-contain object-left lg:col-span-1 col-span-2 w-28" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/alphabet-black.png" alt="Reform" width={158} height={48} /><img className="col-span-2 object-contain object-left lg:col-span-1 w-28" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/meta-black.png" alt="Tuple" width={158} height={48} /><img className="col-span-2 object-contain object-left lg:col-span-1 w-32" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/microsoft-black.png" alt="SavvyCal" width={158} height={48} /><img className="col-span-2 object-contain object-left lg:col-span-1 w-32" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/nvidia-black.png" alt="Statamic" width={158} height={48} />
      </div>
    </div>
  </div>
</div>


            </>
        </React.Fragment>
    );
}

