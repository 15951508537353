import React from 'react';

export default function IndexSectionMaSeContentSections17() {
    return (
        <React.Fragment>
            <>
                <div id="features" className="bg-white py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
      <p className="text-base font-semibold leading-7 text-indigo-600">Customer journeys</p>
      <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Materialize intent</h1>
      <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-gray-700 lg:max-w-none lg:grid-cols-2 text-lg leading-8">
        <div>
          <p>Gain deep insights about your audiences needs and intent. Materialize intent into sales-oriented actions. Discover high intent traffic earlier in the funnel, reach data-driven conclusions faster.</p>
        </div>
        <div />
      </div>
      <div className="lg:mt-10 flex"><a className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" href="#plans">Select plan</a></div>
    </div>
  </div>
  <div className="relative overflow-hidden pt-16 lg:pt-20">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <img className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/MOCKUP-client-journey-variant-A-2.png" alt="" /><div className="relative" aria-hidden="true">
        <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
      </div>
    </div>
  </div>
</div>


            </>
        </React.Fragment>
    );
}

