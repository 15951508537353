import React from 'react';

export default function PrivacyPolicy() {
	return (
		<React.Fragment>
			<>
				<div className='bg-white'>
					<header className='absolute inset-x-0 top-0 z-50'>
						<nav
							className='flex items-center justify-between p-6 lg:px-8'
							aria-label='Global'
						>
							<div className='flex lg:flex-1'>
								<a className='-m-1.5 p-1.5' href='/'>
									<span className='sr-only'>adBooster</span>
									<img
										className='w-auto h-4'
										src='images/Adbooster-Logotype-RGB.png'
										alt=''
									/>
								</a>
							</div>
							<div className='flex flex-1 flex-row justify-end'>
								
							</div>
						</nav>
					</header>
					<div className="flex justify-center items-center">
						<div style={{ paddingBottom: "100px", paddingTop: "100px", paddingLeft: "25px", paddingRight: "25px", maxWidth: '1000px' }} className="text-left px-8">
							<h1 style={{ fontSize: "25px" }}>Use policy</h1>
							<br />

							<h2><strong>PRECONDITIONS</strong></h2>
							<p>All products and services provided by Adbooster or any Adbooster sales channel may be used for lawful purposes only. Transmission or storage of any information, data or material in violation of any State or local laws is strictly prohibited. Customer agrees to indemnify and hold harmless Adbooster from any claims resulting from Customer's use of the service which damages Customer or any other parties, including attorney's fees. Adbooster will not be liable for any interruptions in service or other monetary loss related to enforcement of the Adbooster Master Service Agreement (MSA), including this Acceptable Use Policy.</p>
							<br />

							<h2><strong>SERVICES PROVIDED</strong></h2>
							<p>Adbooster provides Customer with Web-based sales and marketing automation software that includes e-mail, fax, voice broadcast, e-Commerce and affiliate functionality. All services provided must be used by Customer in compliance with the Adbooster Master Service Agreement.</p>
							<br />

							<h2><strong>CUSTOMER OBLIGATIONS</strong></h2>
							<p>Customer agrees to use Adbooster's services in a manner that is legal, appropriate and in conformity with industry standards and to respect the privacy of consumers.</p>
							<br />

							<h2><strong>VIOLATIONS AND PENALTIES</strong></h2>
							<p>Customers who fail to comply with the terms of the Adbooster Master Service Agreement will be subject to immediate termination of service.</p>
							<br />

							<h2><strong>RESERVATION OF RIGHTS</strong></h2>
							<p>Adbooster reserves the right to terminate Customer's account for any violation of this Policy. Adbooster reserves the following rights:<br />
							<br />
								<strong>A) Questionable Practices ("Inappropriate Use")</strong><br />
								Adbooster may terminate Customer's account if Customer engages in any practice that is, in Adbooster's sole discretion, objectionable, unlawful, obscene, pornographic, threatening, abusive, libelous or hateful, or that encourages conduct which would constitute a criminal offense, give rise to civil liability, or otherwise violate any local, state, national or international law. This includes, but is not limited to:<br />
								<br />
								● content that in any way exploits minors under 18 years of age<br />
								● viruses, worms, phishing, malware, or any other potentially harmful software<br />
								● marketing to any lists of associations, memberships, voters or realtors, or any other lists whose recipients did not express explicit consent to receive such marketing material<br />
								● products, services, or content that are often associated with abusive business practices or spam, such as:<br />
								<br />
								– pornography or illicitly pornographic sexual products, including but not limited to adult magazines, video and software, escort services, dating services, or adult "swinger" promotions<br />
								– illegal drugs, software, media, or other goods<br />
								– instructions on how to assemble or otherwise make bombs, or other weaponry<br />
								– online and direct pharmaceutical sales<br />
								– debt collections, credit repair and debt relief offerings<br />
								– stock picks or promotions<br />
								– "get rich quick" and other similar offers<br />
								– promoting pyramid schemes or network marketing (i.e. MLM) businesses<br />
								– odds making and betting/gambling services, including but not limited online casino games, and sporting events<br />
								<br />
								<strong>B) Change of This Policy</strong><br />
								Adbooster reserves the right to change the terms and conditions of this Policy, as needed. Use of Adbooster's software and/or services by Customer after said changes constitutes Customer's acceptance of the new Policy. Adbooster will inform Customer when significant changes are made to any policies under the Adbooster Master Service Agreement by means of the Customer's e-mail, currently on file.</p>
							<br />

							<p>ALL CUSTOMERS AND AFFILIATES ARE EXPECTED TO AGREE TO ALL TERMS CONTAINED HEREIN. DIGITAL ACCEPTANCE IS ACHIEVED WHEN CUSTOMERS OR AFFILIATES ACCESS OR IN ANY WAY USE ADBOOSTER SERVICES.</p>
							<br />

							<p>FAILURE TO AGREE AND COMPLY WILL RESULT IN IMMEDIATE TERMINATION OF SERVICES.</p>
							<br />

							<h2><strong>HOW TO CONTACT US</strong></h2>
							<p>If you have any questions or concerns about this Policy, or should you wish to contact us for any other reason described in this Policy, you can contact us (i) by email directed to legal@adbooster.ae or (ii) by mail addressed to Adbooster LLC, PO Box 38115, Dubai, United Arab Emirates, attention Legal/Privacy Compliance.</p>
							<br />

							<p>This Policy was last updated as of the last date set forth above. If you would like to see an earlier version of this Policy, please contact us using one of the methods set forth above and specify the applicable date or time period for the prior Policy version requested.</p>
							<br />

							<p>© {new Date().getFullYear()} Adbooster LLC. Any rights not expressly granted herein are reserved by Adbooster.</p>
							<br />

							<p>Adbooster is a registered trademark of Adbooster LLC.</p>
						</div>
					</div>
				</div>
			</>
		</React.Fragment>
	);
}

