import React from 'react';

export default function IndexSectionMaSeStatsSections5() {
    return (
        <React.Fragment>
            <>
                <div className="relative isolate overflow-hidden bg-gray-900">
  <img className="absolute inset-0 -z-10 h-full w-full object-cover" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/BG-gradient-mesh-1.png" alt="" /><div className="mx-auto max-w-7xl px-6 lg:px-8 sm:py-24 py-16">
    <div className="mx-auto max-w-2xl lg:max-w-none">
      <div className="text-center">
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Fuel your pipe with smarter data</h2>
        <p className="mt-4 text-lg leading-8 text-gray-300">Data points that matter to your funnel.</p>
      </div>
      <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4"><div className="flex flex-col bg-white/5 p-8"><dt className="text-sm font-semibold leading-6 text-gray-300">Savings on marketing spend</dt><dd className="order-first text-1xl font-semibold tracking-tight text-white">UP TO</dd><dd className="order-first text-3xl font-semibold tracking-tight text-white">40%</dd></div>
        <div className="flex flex-col bg-white/5 p-8"><dt className="text-sm font-semibold leading-6 text-gray-300">More accurate event measurement</dt><dd className="order-first text-1xl font-semibold tracking-tight text-white">UP TO</dd><dd className="order-first text-3xl font-semibold tracking-tight text-white">12x</dd></div>
        <div className="flex flex-col bg-white/5 p-8"><dt className="text-sm font-semibold leading-6 text-gray-300">Higher ROAS compared to manual</dt><dd className="order-first text-1xl font-semibold tracking-tight text-white">UP TO</dd><dd className="order-first text-3xl font-semibold tracking-tight text-white">4x</dd></div>
        <div className="flex flex-col bg-white/5 p-8"><dt className="text-sm font-semibold leading-6 text-gray-300">Spam removal</dt><dd className="order-first text-1xl font-semibold tracking-tight text-white">UP TO</dd><dd className="order-first text-3xl font-semibold tracking-tight text-white">99%</dd></div>
      </dl></div>
  </div>
</div>


            </>
        </React.Fragment>
    );
}

