import React from 'react';

export default function IndexSectionMaSeStatsSections7() {
    return (
        <React.Fragment>
            <>
                <div className="bg-white py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl lg:mx-0">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Get data-driven advantages over your one-off campaigns.</h2>
      <p className="mt-6 text-base leading-7 text-gray-600">✔️ Lower price per qualified conversion ✔️ Higher closure rate ✔️ More insights about your audiences ✔️ Less dependency on manual targeting ✔️ Remove repetitive tasks</p>
    </div>
    <div className="mx-auto mt-16 flex max-w-2xl flex-col space-y-8 lg:space-x-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
      <div className="flex flex-col-reverse justify-between lg:space-y-8 rounded-2xl bg-gray-50 p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
        <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">Basic team</p>
        <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
          <p className="text-lg font-semibold tracking-tight text-gray-900">Basic campaigns</p>
          <p className="mt-2 text-base leading-7 text-gray-600 pb-10 sm:pb-0">Manual one-off ads. Manual creative production. Manual ad management. Manual targeting. Basic measurement. Basic performance.</p>
        </div>
      </div>
      <div className="flex flex-col-reverse justify-between space-y-0 lg:space-y-24 rounded-2xl bg-gray-900 p-8 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start">
        <p className="flex-none text-3xl font-bold tracking-tight text-white md:pb-0 pt-24 lg:pt-0">Performance team</p>
        <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
          <p className="text-lg font-semibold tracking-tight text-white lg:pt-36">Enhanced campaigns</p>
          <p className="mt-2 text-base leading-7 text-gray-400 pb-10 sm:pb-0">Manual one-off ads. Manual creative production. Manual ad management. Enhanced targeting. Enhanced measurement. Enhanced a/b testing. Enhanced insights. Enhanced funnels. Manual localization. Enhanced performance.</p>
        </div>
      </div>
      <div className="flex flex-col-reverse justify-between space-y-0 sm:space-y-8 rounded-2xl bg-indigo-600 p-8 sm:w-11/12 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start">
        <p className="flex-none text-3xl font-bold tracking-tight text-white lg:pt-0 pt-8">adBooster team</p>
        <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
          <p className="text-lg font-semibold tracking-tight text-white pb-80">Reduce admin by up to 99%</p>
          <p className="text-lg font-semibold tracking-tight text-white">Automated feed campaigns</p>
          <p className="mt-2 text-base leading-7 text-indigo-200">CRM sync. Feed based data-driven dynamic ads. Automated creative production. Co-pilot ad management. AI-targeting with advanced matching. Advanced measurement. A/b testing at scale. Traffic 2FA. Deep insights. Customer journeys. Advanced reporting. Dynamic localization. Omnichannel delivery. Smart feedback loops. Click fraud protection. Ad personalization with 3rd-party data and smart dependencies. Traffic qualification and segmentation. Superior performance.</p>
        </div>
      </div>
    </div>
  </div>
</div>


            </>
        </React.Fragment>
    );
}

