import React from 'react';

export default function IndexSectionMaSeStatsSections20() {
    return (
        <React.Fragment>
            <>
                <div className="bg-white sm:py-32 py-24">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl lg:max-w-none">
      <div className="text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" contentEditable="false">Status of adBooster products</h2>
        <p className="mt-4 leading-8 text-gray-600 text-xs">updated 8:41 AM GMT+4</p>
      </div>
      <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
        <div className="flex flex-col bg-gray-400/5 p-8">
          <dt className="text-sm font-semibold leading-6 text-gray-600">APIs</dt>
          <dd className="order-first font-semibold tracking-tight text-gray-900 text-3xl">100% 🟢</dd>
        </div>
        <div className="flex flex-col bg-gray-400/5 p-8">
          <dt className="text-sm font-semibold leading-6 text-gray-600">Services</dt>
          <dd className="order-first font-semibold tracking-tight text-gray-900 text-3xl">100% 🟢</dd>
        </div>
        <div className="flex flex-col bg-gray-400/5 p-8">
          <dt className="text-sm font-semibold leading-6 text-gray-600">Processes</dt>
          <dd className="order-first font-semibold tracking-tight text-gray-900 text-3xl">100% 🟢</dd>
        </div>
        <div className="flex flex-col bg-gray-400/5 p-8">
          <dt className="text-sm font-semibold leading-6 text-gray-600" contentEditable="false">Uptime</dt>
          <dd className="order-first font-semibold tracking-tight text-gray-900 text-3xl" contentEditable="false">99.9%</dd>
        </div>
      </dl>
    </div>
  </div>
</div>


            </>
        </React.Fragment>
    );
}

