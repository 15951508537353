import React from 'react';

export default function IndexSectionMaSeCtaSections11() {
    return (
        <React.Fragment>
            <>
                <div className="relative isolate bg-white pb-32 pt-24 sm:pt-32">
  <div className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl" aria-hidden="true">
    <svg className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem]" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%"><stop offset="0%" style={{stopColor: '#ff80b5', stopOpacity: 1}} /><stop offset="100%" style={{stopColor: '#9089fc', stopOpacity: 1}} /></linearGradient></defs><polygon points="74.1,44.1 100,61.6 97.5,26.9 85.5,0.1 80.7,2 72.5,32.5 60.2,62.4 52.4,68.1 47.5,58.3 45.2,34.5 27.5,76.7 0.1,64.9 17.9,100 27.6,76.8 76.1,97.7 74.1,44.1" fill="url(#grad1)" /></svg></div>
  <div className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden opacity-25 blur-3xl sm:pt-40 xl:justify-end pt-32" aria-hidden="true">
    <svg className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem]" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="100%"><stop offset="0%" style={{stopColor: '#ff80b5', stopOpacity: 1}} /><stop offset="100%" style={{stopColor: '#9089fc', stopOpacity: 1}} /></linearGradient></defs><polygon points="74.1,44.1 100,61.6 97.5,26.9 85.5,0.1 80.7,2 72.5,32.5 60.2,62.4 52.4,68.1 47.5,58.3 45.2,34.5 27.5,76.7 0.1,64.9 17.9,100 27.6,76.8 76.1,97.7 74.1,44.1" fill="url(#grad2)" /></svg></div>
  <div className="px-6 sm:px-6 lg:px-8">
    <div className="justify-center flex pb-3">
      <img className="w-auto h-40" src="https://static.shuffle.dev/uploads/files/f5/f5ebbe1424ca819e09e3314c8f28f8dc064b7cd4/favicon-platonic.png" alt="" /></div>
    <div className="mx-auto max-w-2xl text-center">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        <span>Sharpen your performance.</span>
        <br /><span>Embrace the data-driven mindset.</span>
      </h2>
      <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">Elevate your marketing with localized feeds. Personalize your ads with dynamic creatives.</p>
      <div className="mt-10 flex items-center justify-center space-x-6">
        <a className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" href="#plans">Select plan</a>
        <a className="text-sm font-semibold leading-6 text-gray-900" href="/">
          <span>Learn more</span>
          <span aria-hidden="true">→</span>
        </a>
      </div>
    </div>
  </div>
</div>


            </>
        </React.Fragment>
    );
}

