import React, { useState } from 'react'

export default function CompanySectionMaElHeaders14() {
  const [toggle, setToggle] = useState(false)
  return (
    <React.Fragment>
      <>
        <header className="absolute inset-x-0 top-0 z-50"><nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global"><div className="flex lg:flex-1">
          <a className="-m-1.5 p-1.5" href="/">
            <span className="sr-only">adBooster</span>
            <img className="w-auto h-4" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/Adbooster-Logotype-RGB.png" alt="" /></a>
        </div>
          <div className="flex lg:hidden">
            <button onClick={() => setToggle(!toggle)} className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400" type="button">
              <span className="sr-only">Open main menu</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" /></svg></button>
          </div>
          <div className="hidden lg:flex"><a className="text-sm font-semibold mr-12 leading-6" href="/">Products</a><a className="text-sm font-semibold mr-12 leading-6" href="/">Features</a><a className="text-sm font-semibold mr-12 leading-6" href="/">Resources</a><a className="text-sm font-semibold leading-6" href="/company">Company</a></div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a className="text-sm font-semibold leading-10" href="https://app.adbooster.ai">
              <span>Log in</span>
              <span aria-hidden="true">→</span>
            </a>
          </div>
        </nav>{/* Mobile menu, show/hide based on menu open state.*/}
          <div className={(toggle ? "" : "hidden") + " lg:hidden"} role="dialog" aria-modal="true">
            {/* <div class="lg:hidden" role="dialog" aria-modal="true"> */}
            {/* Background backdrop, show/hide based on slide-over state.*/}
            <div className="fixed inset-0 z-50" />
            <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
              <div className="flex items-center justify-between">
                <a className="-m-1.5 p-1.5" href="/">
                  <span className="sr-only">adBooster</span>
                  <img className="h-8 w-auto" src="https://static.shuffle.dev/uploads/files/8a/8a488a5d97034fff64faff3fc40392c50ec7be90/Adbooster-Favicon-RGB-Inv.png" alt="" /></a>
                <button onClick={() => setToggle(!toggle)} className="-m-2.5 rounded-md p-2.5 text-gray-400" type="button">
                  <span className="sr-only">Close menu</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" /></svg></button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/25">
                  <div className="space-y-2 py-6"><a className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800" href="/">Products</a><a className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800" href="/">Features</a><a className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800" href="/">Resources</a><a className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800" href="/company">Company</a></div>
                  <div className="py-6"><a className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800" href="https://app.adbooster.ai">Log in</a></div>
                </div>
              </div>
            </div>
          </div>
        </header>


      </>
    </React.Fragment>
  );
}

