import React from 'react';

export default function IndexSectionMaSePricing13() {
    return (
        <React.Fragment>
            <>
                <div id="plans" className="bg-white py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-4xl text-center">
      <h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2>
      <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Plans for teams of all sizes</p>
    </div>
    <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">All plans are subject to min 6 month contract, local variations may occur. Marketing spend not included. Local taxes may apply.</p>
    {/* xs to lg*/}
    <div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
      <section className="p-8"><h3 className="text-sm font-semibold leading-6 text-gray-900" id="tier-basic">Scale Meta</h3>
        <p className="mt-2 flex items-baseline space-x-1 text-gray-900">
          <span className="text-4xl font-bold">$799</span>
          <span className="text-sm font-semibold ml-1">/month</span>
        </p>
        <a className="mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300" target={"_blank"} href={"mailto:welcome@adbooster.ae?subject=Scale Meta&body="} aria-describedby="tier-basic">Buy plan</a>
        <ul className="mt-10 space-y-4 text-sm leading-6 text-gray-900" role="list"><li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Scale dashboard</span>
              </li>
              <li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>White label</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Send traffic forms</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Traffic 2FA</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Unlimited listings</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Unlimited agents</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Meta only setup</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>2 feeds included</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Dynamic localization</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Customer journeys</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Advanced filtering</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Advanced utm tagging</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Insights</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Reporting</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Advanced matching</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Advanced measurement</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Smart feedback loops</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>1 CRM integration included</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>CRM sync</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>2 dynamic creative templates included</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>1 day setup time</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>proptr.ee </span>
                  <span className="text-sm leading-6 text-gray-500">(real estate only)</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Feed </span>
                  <span className="text-sm leading-6 text-gray-500">+135 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Custom creative template </span>
                  <span className="text-sm leading-6 text-gray-500">+269 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Traffic qualification </span>
                  <span className="text-sm leading-6 text-gray-500">+269 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Click fraud protection </span>
                  <span className="text-sm leading-6 text-gray-500">+49 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Co-pilot ad management </span>
                  <span className="text-sm leading-6 text-gray-500">+99 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Basic support </span>
                  <span className="text-sm leading-6 text-gray-500">(max 1h / month)</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Dedicated support </span>
                  <span className="text-sm leading-6 text-gray-500">(6h / month) +399 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Managed service </span>
                  <span className="text-sm leading-6 text-gray-500">+679 / month</span>
                </span>
              </li>
            </ul></li>
        </ul></section><section className="p-8 rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200"><h3 className="text-sm font-semibold leading-6 text-gray-900" id="tier-essential">Scale Pro (waiting list)</h3>
        <p className="mt-2 flex items-baseline space-x-1 text-gray-900">
          <span className="text-4xl font-bold">$1099</span>
          <span className="text-sm font-semibold ml-1">/month</span>
        </p>
        <a className="mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-indigo-600 text-white hover:bg-indigo-500" target={"_blank"} href={"mailto:welcome@adbooster.ae?subject=Scale Pro&body="} aria-describedby="tier-essential">Buy plan</a>
        <ul className="mt-10 space-y-4 text-sm leading-6 text-gray-900" role="list"><li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Scale dashboard</span>
              </li>
              <li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>White label</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Send traffic forms</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Send traffic website</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Traffic 2FA</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Unlimited listings</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Unlimited agents</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>2 ad network setup included</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>3 feeds included</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Dynamic localization</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Customer journeys</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Advanced filtering</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Advanced utm tagging</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Insights</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Reporting</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Advanced matching</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Advanced measurement</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Smart feedback loops</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>1 CRM integration included</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>CRM sync</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>3 dynamic creative templates included</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>2-3 days setup time</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>proptr.ee </span>
                  <span className="text-sm leading-6 text-gray-500">(real estate only)</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Feed </span>
                  <span className="text-sm leading-6 text-gray-500">+269 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Ad network setup </span>
                  <span className="text-sm leading-6 text-gray-500">+269 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>CRM integration </span>
                  <span className="text-sm leading-6 text-gray-500">+269 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Custom creative template </span>
                  <span className="text-sm leading-6 text-gray-500">+269 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Data-driven creative template </span>
                  <span className="text-sm leading-6 text-gray-500">+539 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Traffic qualification </span>
                  <span className="text-sm leading-6 text-gray-500">+269 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Click fraud protection </span>
                  <span className="text-sm leading-6 text-gray-500">+49 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Co-pilot ad management </span>
                  <span className="text-sm leading-6 text-gray-500">+99 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Basic support </span>
                  <span className="text-sm leading-6 text-gray-500">(max 1h / month)</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Dedicated support </span>
                  <span className="text-sm leading-6 text-gray-500">(6h / month) +399 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Managed service </span>
                  <span className="text-sm leading-6 text-gray-500">+879 / month</span>
                </span>
              </li>
            </ul></li>
        </ul></section><section className="p-8"><h3 className="text-sm font-semibold leading-6 text-gray-900" id="tier-premium">Scale Enterprise</h3>
        <p className="mt-2 flex items-baseline space-x-1 text-gray-900">
          <span className="text-4xl font-bold">$</span>
          <span className="text-sm font-semibold ml-1">ASK FOR PRICE</span>
        </p>
        <a className="mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300" target={"_blank"} href={"mailto:welcome@adbooster.ae?subject=Scale Enterprise&body="} aria-describedby="tier-premium">Buy plan</a>
        <ul className="mt-10 space-y-4 text-sm leading-6 text-gray-900" role="list"><li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Scale dashboard</span>
              </li>
              <li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>White label</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Send traffic forms</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Send traffic website</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Traffic 2FA</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Unlimited listings</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Unlimited agents</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>3 ad network setup included</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Pay per feed</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Video feeds </span>
                  <span className="text-sm leading-6 text-gray-500">(coming soon)</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Dynamic localization</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Customer journeys</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Advanced filtering</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Advanced utm tagging</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Insights</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Advanced reporting</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Advanced matching</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Advanced measurement</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Smart feedback loops</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>1 CRM integration included</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>CRM sync</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>3 dynamic creative templates included</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Dynamic video templates </span>
                  <span className="text-sm leading-6 text-gray-500">(coming soon)</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>10-14 days setup time</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>proptr.ee </span>
                  <span className="text-sm leading-6 text-gray-500">(real estate only)</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Feed </span>
                  <span className="text-sm leading-6 text-gray-500">+269 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Ad network setup </span>
                  <span className="text-sm leading-6 text-gray-500">+269 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>CRM integration </span>
                  <span className="text-sm leading-6 text-gray-500">+269 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>1 custom creative template included</span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Data-driven creative template </span>
                  <span className="text-sm leading-6 text-gray-500">+539 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Traffic qualification </span>
                  <span className="text-sm leading-6 text-gray-500">+269 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Click fraud protection </span>
                  <span className="text-sm leading-6 text-gray-500">+49 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Co-pilot ad management </span>
                  <span className="text-sm leading-6 text-gray-500">+99 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Priority support </span>
                  <span className="text-sm leading-6 text-gray-500">(max 2h / month)</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Dedicated support </span>
                  <span className="text-sm leading-6 text-gray-500">(6h / month) +399 / month</span>
                </span>
              </li>
            </ul></li>
          <li>
            <ul className="space-y-4" role="list"><li className="flex space-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>
                  <span>Managed service </span>
                  <span className="text-sm leading-6 text-gray-500">+999 / month</span>
                </span>
              </li>
            </ul></li>
        </ul></section></div>
    {/* lg+*/}
    <div className="isolate mt-20 hidden lg:block">
      <div className="relative -mx-8">
        <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
          <div className="flex w-1/4 px-4" style={{marginLeft: '50%'}} aria-hidden="true">
            <div className="w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5" />
          </div>
        </div>
        <table className="w-full table-fixed border-separate border-spacing-x-8 text-left"><caption className="sr-only">Pricing plan comparison</caption><colgroup><col className="w-1/4" /><col className="w-1/4" /><col className="w-1/4" /><col className="w-1/4" /></colgroup><thead><tr><td />
              <th className="px-6 pt-6 xl:px-8 xl:pt-8" scope="col">
                <div className="text-sm font-semibold leading-7 text-gray-900">Scale Meta</div>
              </th>
              <th className="px-6 pt-6 xl:px-8 xl:pt-8" scope="col">
                <div className="text-sm font-semibold leading-7 text-gray-900">Scale Pro (waiting list)</div>
              </th>
              <th className="px-6 pt-6 xl:px-8 xl:pt-8" scope="col">
                <div className="text-sm font-semibold leading-7 text-gray-900">Scale Enterprise</div>
              </th>
            </tr></thead><tbody><tr><th scope="row">
                <span className="sr-only">Price</span>
              </th>
              <td className="px-6 pt-2 xl:px-8">
                <div className="flex items-baseline space-x-1 text-gray-900">
                  <span className="text-4xl font-bold">$799</span>
                  <span className="text-sm font-semibold leading-6 ml-1">/month</span>
                </div>
                <a className="mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300" target={"_blank"} href={"mailto:welcome@adbooster.ae?subject=Scale Meta&body="}>Buy plan</a>
              </td>
              <td className="px-6 pt-2 xl:px-8">
                <div className="flex items-baseline space-x-1 text-gray-900">
                  <span className="text-4xl font-bold">$1099</span>
                  <span className="text-sm font-semibold leading-6 ml-1">/month</span>
                </div>
                <a className="mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-indigo-600 text-white hover:bg-indigo-500" target={"_blank"} href={"mailto:welcome@adbooster.ae?subject=Scale Pro&body="}>Buy plan</a>
              </td>
              <td className="px-6 pt-2 xl:px-8">
                <div className="flex items-baseline space-x-1 text-gray-900">
                  <span className="text-4xl font-bold">$</span>
                  <span className="text-sm font-semibold leading-6 ml-1">ASK FOR PRICE</span>
                </div>
                <a className="mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300" target={"_blank"} href={"mailto:welcome@adbooster.ae?subject=Scale Enterprise&body="}>Buy plan</a>
              </td>
            </tr><tr><th className="pb-4 text-sm font-semibold leading-6 text-gray-900 pt-8" scope="colgroup" colSpan={4}>
                <span>Features</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
              </th>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Scale dashboard</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Enterprise</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>White label</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Enterprise</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Send traffic forms</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Enterprise</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Send traffic website</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" /></svg><span className="sr-only">Not included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Enterprise</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Traffic 2FA</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Enterprise</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Unlimited listings</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Enterprise</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Unlimited agents</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Enterprise</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Ad network setup</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">Meta only</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">2 included</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">3 included</div>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Feeds</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">2 included</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">3 included</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">Pay per feed</div>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Video feeds</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" /></svg><span className="sr-only">Not included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" /></svg><span className="sr-only">Not included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">Coming soon</div>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Dynamic localization</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Premium</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Customer journeys</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Premium</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Advanced filtering</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Premium</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Advanced utm tagging</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Premium</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Insights</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Premium</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Reporting</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" /></svg><span className="sr-only">Not included in Enterprise</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Advanced reporting</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" /></svg><span className="sr-only">Not included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" /></svg><span className="sr-only">Not included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Enterprise</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Advanced matching</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Enterprise</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Advanced measurement</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Enterprise</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Omnichannel delivery</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" /></svg><span className="sr-only">Not included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Enterprise</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Smart feedback loops</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Enterprise</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>CRM integration</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">1 included</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">1 included</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">1 included</div>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>CRM sync</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Enterprise</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Dynamic creative template</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">2 included</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">3 included</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">3 included</div>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Dynamic video template</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" /></svg><span className="sr-only">Not included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" /></svg><span className="sr-only">Not included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">Coming soon</div>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Setup time</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">1 day</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">2-3 days</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">10-14 days</div>
              </td>
            </tr><tr><th className="pb-4 text-sm font-semibold leading-6 text-gray-900 pt-16" scope="colgroup" colSpan={4}>
                <span>Add-ons</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
              </th>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>proptr.ee (real estate only)</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Enterprise</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Feeds</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+135 / month</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+269 / month</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+269 / month</div>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Ad network setup</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" /></svg><span className="sr-only">Not included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+269 / month</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+269 / month</div>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>CRM integration</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" /></svg><span className="sr-only">Not included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+269 / month</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+269 / month</div>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Custom creative template</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+269 / month</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+269 / month</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">1 included</div>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Data-driven creative template</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" /></svg><span className="sr-only">Not included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+539 / month</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+539 / month</div>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Traffic qualification</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+269 / month</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+269 / month</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+269 / month</div>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Click fraud protection</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+49 / month</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+49 / month</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+49 / month</div>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Co-pilot ad management</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+99 / month</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+99 / month</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+99 / month</div>
              </td>
            </tr><tr><th className="pb-4 text-sm font-semibold leading-6 text-gray-900 pt-16" scope="colgroup" colSpan={4}>
                <span>Support</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
              </th>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Basic support (max 1h / month)</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" /></svg><span className="sr-only">Not included in Enterprise</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Priority support (max 2h / month)</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" /></svg><span className="sr-only">Not included in Meta</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" /></svg><span className="sr-only">Not included in Pro</span>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span className="sr-only">Included in Enterprise</span>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Dedicated support (6h / month)</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+399 / month</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+399 / month</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+399 / month</div>
              </td>
            </tr><tr><th className="py-4 text-sm font-normal leading-6 text-gray-900" scope="row">
                <span>Managed service</span>
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
              </th>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+679 / month</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+879 / month</div>
              </td>
              <td className="px-6 py-4 xl:px-8">
                <div className="text-center text-sm leading-6 text-gray-500">+999 / month</div>
              </td>
            </tr></tbody></table></div>
    </div>
  </div>
</div>


            </>
        </React.Fragment>
    );
}

