import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import CompanyPage from './pages/Company.js';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import UsePolicy from './pages/UsePolicy';

function App() {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route path="/company" element={<CompanyPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/use-policy" element={<UsePolicy />} />
      <Route path="/terms" element={<Terms />} />
    </Routes>
  );
}

export default App;
