import React from 'react';

export default function IndexSectionMaSePricing16() {
  return (
    <React.Fragment>
      <>
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl sm:text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Bulk advertisers look here</h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">Advanced solutions for marketplaces and large media resellers looking to monetize the latest adtech.</p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
              <div className="p-8 sm:p-10 lg:flex-auto">
                <h3 className="text-2xl font-bold tracking-tight text-gray-900">Paid ads @ scale</h3>
                <p className="mt-6 text-base leading-7 text-gray-600">Enable your users to boost unlimited inventory. Resell the world’s leading ad networks. Integrate directly with your order flow.</p>
                <div className="mt-10 flex items-center space-x-4">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">Benefits</h4>
                  <div className="h-px flex-auto bg-gray-100" />
                </div>
                <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6" role="list"><li className="flex space-x-3">
                  <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Unlock new revenue</span>
                </li>
                  <li className="flex space-x-3">
                    <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Bundle into your subscriptions</span>
                  </li>
                  <li className="flex space-x-3">
                    <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Increase traffic to your site</span>
                  </li>
                  <li className="flex space-x-3">
                    <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" /></svg><span>Increase retention</span>
                  </li>
                </ul></div>
              <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                  <div className="mx-auto max-w-xs px-8">
                    <p className="text-base font-semibold text-gray-600">Scale Agency</p>
                    <p className="mt-6 flex items-baseline justify-center space-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">$</span>
                      <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">ASK FOR PRICE</span>
                    </p>

                    <a className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" target={"_blank"} href={"mailto:welcome@adbooster.ae?subject=Scale Agency"}>Request quote</a>
                    <p className="mt-6 text-xs leading-5 text-gray-600">Min 12 month contract. Marketing spend not included. Local taxes may apply.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    </React.Fragment >
  );
}

